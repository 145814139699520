export class Consts {
  /**
   * Path of local storage items
   */
  static consentLocalStorage = 'resmit.consent';
  static accountLocalStorage = 'resmit.account';
  static deviceNameLocalStorage = 'resmit.device.name';
  static deviceIdLocalStorage = 'resmit.device.id';
  static tokenLocalStorage = 'resmit.token';

  /**
   * Placeholders that are shown in multi input
   */
  static placeholders = [
    'File', 'Image', 'Text', 'URL', 'Code'
  ];

  /**
   * All file extensions that are handled and displayed as image
   */
  static imageFileExtensions = ['.png', '.jpeg', '.jpg', '.gif', '.tiff', '.tif', '.svg'];
}
