import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Creates a new user
   * @param email The new users email
   * @param password The new users password
   * @param username The new users username
   */
  public register$(email: string, password: string, username: string): Observable<boolean> {
    return this.httpClient.post(`${environment.backendUrl}/register`, {email, username, password }).pipe(
      map(() => true),
      catchError(() => of(false)),
    );
  }
}
