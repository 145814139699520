import {Injectable, Injector} from '@angular/core';
import {AccountService} from '../account.service';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {StringHelper} from '../../helper/string-helper';
import {AuthTokenService} from '../auth-token.service';

export interface QuickshareOption {
  // Displayed in select
  display: string;

  // The actual value
  value: QuickshareOptionValue;
}

export interface QuickshareOptionValue {
  // Has to equal server stored key
  key: string;
  preview: string;
  url?: string;
  action?: (code: string, injector: Injector) => void;
  attributes?: { [key: string]: any };
}

export interface QuickshareOptionBackend {
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class QuickshareService {

  constructor(private accountService: AccountService, private httpClient: HttpClient, private authTokenService: AuthTokenService) {
  }

  /**
   * Returns current quickshare options.
   * If logged in the users options will get loaded, if not the default ones.
   */
  public getOptions$(): Observable<QuickshareOption[]> {
    return this.accountService.signedIn$.pipe(
      switchMap((signedIn) => {
        if (!signedIn || !this.authTokenService.authToken) {
          return of(defaultQuickShareOptions);
        }

        return this.httpClient.get<QuickshareOptionBackend[]>(`${environment.backendUrl}/quickshare`).pipe(
          map((response: QuickshareOptionBackend[]) => {
            return response
              .map((backendOption) => {
                return quickShareOptions.find(o => o.value.key === backendOption.type);
              })
              .filter(v => !!v);
          })
        );
      }),
      shareReplay()
    );
  }

  /**
   * Stores the quickshare options for the logged in user
   * @param options The quickshare options to store
   */
  public setOptions$(options: QuickshareOptionValue[]): Observable<void> {
    const optionsConverted = options.filter(v => !!v).map(v => ({type: v.key}));
    return this.httpClient.put<void>(`${environment.backendUrl}/quickshare`, optionsConverted);
  }

  /**
   * Returns all available quickshare options
   */
  public getAllOptions(): QuickshareOption[] {
    return quickShareOptions.sort((a, b) => a.display > b.display ? 1 : a.display === b.display ? 0 : -1);
  }
}

const isOsx = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
const isIos = /(iPhone|iPod|iPad)/i.test(navigator.platform);

/**
 * List of all quickshare options
 */
const quickShareOptions: QuickshareOption[] = [
  {
    display: 'E-mail',
    value: {
      key: 'EMAIL',
      preview: '<img src="/assets/icons/icon_email.svg" />',
      url: 'mailto:?subject=Resmit&body={body}'
    }
  },
  {
    display: 'Message (SMS)',
    value: {
      key: 'SMS',
      preview: '<img src="/assets/icons/icon_message.svg" />',
      url: isOsx || isIos ? 'sms:PHONE NUMBER&body={body}' : 'sms:?body={body}'
    }
  },
  {
    display: 'PushSend',
    value: {
      key: 'PUSH_SEND',
      preview: '<img src="/assets/icons/icon_pushsend.svg" />'
    }
  },
  {
    display: 'WhatsApp',
    value: {
      key: 'WHATS_APP',
      preview: '<span class="quickShareText">W</span>',
      attributes: {
        'data-action': 'share/whatsapp/share'
      },
      url: 'whatsapp://send?text={body}'
    }
  },
  {
    display: 'Telegram',
    value: {
      key: 'TELEGRAM',
      preview: '<span class="quickShareText">T</span>',
      url: 'https://telegram.me/share/url?url={body}&text={body}',
      attributes: {
        target: '_blank'
      }
    }
  },
  {
    display: 'Viber',
    value: {
      key: 'VIBER',
      preview: '<span class="quickShareText">V</span>',
      url: 'viber://forward?text={body}'
    }
  },
  {
    display: 'Facebook',
    value: {
      key: 'FACEBOOK',
      preview: '<span class="quickShareText">F</span>',
      url: 'https://www.facebook.com/sharer/sharer.php?display=popup&u={body}',
      attributes: {
        target: '_blank'
      }
    }
  },
  {
    display: 'Twitter',
    value: {
      key: 'TWITTER',
      preview: '<span class="quickShareText">T</span>',
      url: 'https://twitter.com/share?url={body}&hashtags=resmit',
      attributes: {
        target: '_blank'
      }
    }
  },
  {
    display: 'VK',
    value: {
      key: 'VK',
      preview: '<span class="quickShareText">VK</span>',
      url: 'http://vkontakte.ru/share.php?url={body}',
      attributes: {
        target: '_blank'
      }
    }
  },
  {
    display: 'Skype',
    value: {
      key: 'SKYPE',
      preview: '<span class="quickShareText">S</span>',
      url: 'https://web.skype.com/share?url={body}&text={body}',
      attributes: {
        target: '_blank'
      }
    }
  },
  {
    display: 'LinkedIn',
    value: {
      key: 'LINKED_IN',
      preview: '<span class="quickShareText">L</span>',
      url: 'http://www.linkedin.com/shareArticle?mini=true&url={body}',
      attributes: {
        target: '_blank'
      }
    }
  },
  {
    display: 'Open',
    value: {
      key: 'OPEN_IN_NEW_WINDOW',
      preview: '<img src="/assets/icons/icon_open.svg" />',
      url: '{body}'
    }
  },
  {
    display: 'Copy to clipboard',
    value: {
      key: 'COPY_TO_CLIPBOARD',
      preview: '<img src="/assets/icons/icon_copy.svg" />',
      action: (code: string) => {
        const url = StringHelper.replaceQuicksharePlaceholder('{body}', code);

        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      }
    },
  },
  {
    display: 'Share',
    value: {
      key: 'WEB_SHARE',
      preview: '<img src="/assets/icons/icon_share.svg" />',
      action: (code: string) => {
        if ((navigator as any).share) {
          (navigator as any).share({
            title: 'Resmit',
            url: StringHelper.replaceQuicksharePlaceholder('{body}', code)
          } as any);
        }
      }
    },
  },
];

/**
 * Default quickshare options. Used when no custom configuration for current user was found
 */
const defaultQuickShareOptions: QuickshareOption[] = [
  // quickShareOptions[0],
  // quickShareOptions[1],
  // quickShareOptions[3],
  // quickShareOptions[5],
  // quickShareOptions[7],


  // Default options
  quickShareOptions[0],
  quickShareOptions[1],
  quickShareOptions[3],
  quickShareOptions[4],
  quickShareOptions[6]
];
