import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-support-help',
  templateUrl: './support-help.component.html',
  styleUrls: ['./support-help.component.less']
})
export class SupportHelpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
