import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';

/**
 * Unwraps a variable without checking for truthy
 * Usable to rerender content on change of variable or to unwrap observable with possible falsy values
 */
@Directive({
  selector: '[appLet]',
  exportAs: 'let'
})
export class LetDirective {

  @Input()
  set appLet(context: any) {
    this.context.$implicit = this.context.ngVar = context;
    this.updateView();
  }

  private context: any = {};

  constructor(private vcRef: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  updateView() {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }

}
