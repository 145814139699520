import {EventEmitter, Injectable} from '@angular/core';
import {LoginResponse} from './api/auth.service';
import {Consts} from '../consts';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenService {

  public tokenWrongEvent = new EventEmitter<void>();

  authToken: LoginResponse;

  constructor() {
    const authTokenData: LoginResponse = JSON.parse(localStorage.getItem(Consts.tokenLocalStorage));

    if (!!authTokenData) {
      this.authToken = authTokenData;
    }
  }

  /**
   * Sets the current auth token
   * @param authToken The new auth token
   * @param remember Set if the auth token should get stored in local storage
   */
  setData(authToken: LoginResponse, remember: boolean) {
    this.authToken = authToken;

    if (remember) {
      localStorage.setItem(Consts.tokenLocalStorage, JSON.stringify(this.authToken));
    } else {
      localStorage.removeItem(Consts.tokenLocalStorage);
    }
  }

  /**
   * Deletes auth token data of the instance
   */
  resetData() {
    this.authToken = null;
    localStorage.removeItem(Consts.tokenLocalStorage);
  }
}
