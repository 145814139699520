<div class="select" [class.open]="open" #selectElement>
  <div class="options">
    <div class="option" (click)="selectOption(option)" *ngFor="let option of options">{{ option.display }}</div>
  </div>

  <div class="preview" [class.placeholder]="!selected || !selected.value" (click)="openSelect($event)">
    {{ selected && selected.value ? selected.display : placeholder }}
  </div>
  <div class="arrow" (click)="openSelect($event)">
    <img [src]="selected ? '/assets/icons/icon_select_triangle_active.svg' : '/assets/icons/icon_select_triangle.svg'"
         [class.rotate]="open" />
  </div>
</div>
