import {Component, Injector, Input, OnInit} from '@angular/core';
import {QuickshareOption, QuickshareService} from '../../services/api/quickshare.service';
import {fromEvent, Observable, timer} from 'rxjs';
import {DeviceData, DeviceService} from '../../services/api/device.service';
import {CodeShareService} from '../../services/api/code-share.service';
import {map, shareReplay, skip, switchMap, take} from 'rxjs/operators';
import {slideInAnimation} from '../../animations/slideIn';
import {fadeInStateAnimation} from '../../animations/fadeIn';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-quick-share-bar',
  templateUrl: './quick-share-bar.component.html',
  styleUrls: ['./quick-share-bar.component.less'],
  animations: [
    slideInAnimation(),
    fadeInStateAnimation()
  ]
})
export class QuickShareBarComponent implements OnInit {
  @Input() code: string;

  selectDeviceActive = false;

  options$: Observable<QuickshareOption[]>;

  devices$: Observable<DeviceData[]>;

  constructor(private quickshareService: QuickshareService, public injector: Injector, private deviceService: DeviceService,
              private codeShareService: CodeShareService) {
    this.options$ = this.quickshareService.getOptions$();
    this.devices$ = timer(0, environment.pushSendDevicePollingTime).pipe(
      switchMap(() => this.deviceService.getDevices$()),
      shareReplay()
    );
  }

  ngOnInit(): void {
  }

  /**
   * Opens the device menu to see available devices for push send
   */
  openPushSend() {
    if (!this.selectDeviceActive) {
      fromEvent(document, 'click').pipe(
        skip(1),
        take(1)
      ).subscribe(() => {
        this.selectDeviceActive = false;
      });
    }

    this.selectDeviceActive = true;
  }

  /**
   * Shares the code with a gieven device or on next login if no device id is provided
   * @param deviceId The device id
   */
  share(deviceId?: string) {
    this.selectDeviceActive = false;

    if (deviceId) {
      this.codeShareService.shareWithDevice(this.code, [deviceId], false);
    } else {
      this.codeShareService.shareWithDevice(this.code, [], true);
    }
  }
}
