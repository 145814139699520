import {animate, query, style, transition, trigger} from '@angular/animations';

/**
 * Transition of pages
 * Simple fadeIn/fadeOut
 */
export const pageTransition = (name: string = 'pageTransition', setPositionAbsolute: boolean = false) => {
  return trigger(name, [
    // Special animation for transition from start-page
    transition('start => *', [
      // Hide new element
      query(':enter', [
        setPositionAbsolute ?
          style({ opacity: 0, position: 'absolute', top: 0, overflow: 'hidden', zIndex: 2 }) :
          style({ opacity: 0 })
      ], { optional: true }),

      query(':enter > *', [
        style({ opacity: 0 })
      ], { optional: true }),

      query('.sub-page', [
        style({ overflow: 'hidden', overflowY: 'hidden', overflowX: 'hidden' })
      ], { optional: true }),

      // Animate old element
      query(':leave > *, :leave', [
        style({ opacity: 1 }),
        animate('250ms ease', style({ opacity: 0 })),
        style({ position: 'absolute', overflow: 'hidden' })
      ], { optional: true }),

      // Animate new element
      query(':enter > *, :enter', [
        style({ opacity: 0 }),
        animate('250ms ease', style({ opacity: 1 }))
      ], { optional: true })
    ]),

    // Special animation for transition to start-page
    transition('* => start', [
      // Hide new element
      query(':enter', [
        setPositionAbsolute ?
          style({ opacity: 0, position: 'absolute', top: 0, overflow: 'hidden', zIndex: 2 }) :
          style({ opacity: 0 })
      ], { optional: true }),

      query(':enter > *', [
        style({ opacity: 0 })
      ], { optional: true }),

      query('.sub-page', [
        style({ overflow: 'hidden', overflowY: 'hidden', overflowX: 'hidden' })
      ], { optional: true }),

      // Animate old element
      query(':leave > * > *, :leave > *, :leave', [
        style({ opacity: 1 }),
        animate('250ms ease', style({ opacity: 0 })),
        style({ position: 'absolute', overflow: 'hidden' })
      ], { optional: true }),

      // Animate new element
      query(':enter > *, :enter', [
        style({ opacity: 0 }),
        animate('250ms ease', style({ opacity: 1 }))
      ], { optional: true })
    ]),

    // Default transition
    transition('* => *', [
      // Hide new element
      query(':enter', [
        setPositionAbsolute ?
          style({ opacity: 0, position: 'absolute', top: '30px', overflow: 'hidden', width: 'calc(100vw - 60px)', zIndex: 2 }) :
          style({ opacity: 0 })
      ], { optional: true }),

      query(':enter > *, :enter', [
        style({ opacity: 0, overflow: 'hidden' })
      ], { optional: true }),

      query('.sub-page', [
        style({ overflow: 'hidden', overflowY: 'hidden', overflowX: 'hidden' })
      ], { optional: true }),

      // Animate old element
      query(':leave > *, :leave', [
        style({ opacity: 1 }),
        animate('250ms ease', style({ opacity: 0 })),
        style({ position: 'absolute', overflow: 'hidden' })
      ], { optional: true }),

      // Animate new element
      query(':enter > *, :enter', [
        style({ opacity: 0 }),
        animate('250ms ease', style({ opacity: 1 }))
      ], { optional: true })
    ])
  ]);
};
