import {Injectable} from '@angular/core';
import {AccountService} from '../account.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, filter, map, shareReplay, switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {Consts} from '../../consts';

declare const FRUBIL: any;

export interface DeviceData {
  id: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
  ready$ = new BehaviorSubject(false);
  device: DeviceData;

  constructor(private accountService: AccountService, private httpClient: HttpClient) {
    this.accountService.currentUser$.subscribe((currentUser) => {
      this.ready$.next(false);

      if (currentUser) {
        this.device = {
          name: localStorage.getItem(Consts.deviceNameLocalStorage) || `${FRUBIL.device.class} - ${FRUBIL.client.os}`,
          id: currentUser.deviceId
        };

        this.updateDevice$(this.device.name).subscribe(() => {
          this.setDeviceData(this.device);
          this.ready$.next(true);
        }, () => {
          this.accountService.signOut();
          this.setDeviceData({ ...this.device, id: undefined });
          this.ready$.next(true);
        });
      } else {
        if (this.device?.id) {
          this.unregisterDevice$(this.device.id).subscribe();
        }
      }
    });
  }

  public getDevices$(): Observable<DeviceData[]> {
    return this.ready$.pipe(
      filter(v => v),
      switchMap(() => this.httpClient.get<DeviceData[]>(`${environment.backendUrl}/device`)),
      map((devices) => devices.filter(d => d.id !== this.device?.id)),
      shareReplay()
    );
  }

  public registerDevice$(name: string): Observable<DeviceData> {
    return this.httpClient.post<DeviceData>(`${environment.backendUrl}/device`, { name });
  }

  public unregisterDevice$(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${environment.backendUrl}/device/${id}`).pipe(
      catchError(() => of(null))
    );
  }

  public updateDevice$(name: string): Observable<void> {
    return this.httpClient.put<void>(`${environment.backendUrl}/device/${this.device.id}`, { name });
  }

  public setDeviceName(name: string) {
    if (this.device?.id && name) {
      this.updateDevice$(name).subscribe(() => {
        this.setDeviceData({
          ...this.device,
          name
        });
      });
    }
  }

  private setDeviceData(data: DeviceData) {
    this.device = data;

    if (this.device.name) {
      localStorage.setItem(Consts.deviceNameLocalStorage, this.device.name);
    } else {
      localStorage.removeItem(Consts.deviceNameLocalStorage);
    }
  }
}
