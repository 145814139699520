import { Pipe, PipeTransform } from '@angular/core';
import {CodeData} from '../../shared/services/api/code.service';

@Pipe({
  name: 'onlyFiles'
})
export class OnlyFilesPipe implements PipeTransform {

  transform(value: CodeData): boolean {
    return value.data?.findIndex(d => d.type === 'LINK') === -1 && value.data?.findIndex(d => d.type === 'TEXT') === -1;
  }

}
