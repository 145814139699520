import {AfterViewInit, Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core';
import {StringHelper} from '../helper/string-helper';
import {AccountService} from '../services/account.service';
import {Subscription} from 'rxjs';

/**
 * Applies all given attributes to the element
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[attr]'
})
export class AttrDirective implements AfterViewInit, OnDestroy {

  @Input('attr') attributes: { [attribute: string]: any };
  @Input() code: string;

  subscription: Subscription;

  constructor(private element: ElementRef, private renderer: Renderer2, private accountService: AccountService) { }

  ngAfterViewInit(): void {
    if (this.attributes) {
      this.subscription = this.accountService.currentUser$.subscribe(user => {
        Object.keys(this.attributes).forEach(attribute => {
          const attributeValueRaw = this.attributes[attribute];

          if (attributeValueRaw) {
            const attributeValue = StringHelper.replaceQuicksharePlaceholder(attributeValueRaw, this.code, user);
            this.renderer.setAttribute(this.element.nativeElement, attribute, attributeValue);
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
