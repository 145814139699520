import {Component, ElementRef, HostBinding, Input, OnChanges, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-smooth-height',
  templateUrl: './smooth-height.component.html',
  styleUrls: ['./smooth-height.component.less'],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [
        style({height: '{{startHeight}}px', opacity: 0}),
        animate('250ms ease'),
      ], {params: {startHeight: 0}})
    ])
  ]
})
export class SmoothHeightComponent implements OnInit, OnChanges {

  @Input() trigger: any;

  startHeight: number;

  constructor(private element: ElementRef) { }

  @HostBinding('@grow') get grow() {
    return {
      value: this.trigger,
      params: {
        startHeight: this.startHeight
      }
    };
  }

  ngOnInit(): void {
  }


  setStartHeight() {
    this.startHeight = this.element.nativeElement.clientHeight;
  }

  ngOnChanges() {
    this.setStartHeight();
  }
}
