import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subscription, timer} from 'rxjs';
import {filter, map, shareReplay, startWith} from 'rxjs/operators';
import {DialogService} from './shared/components/dialog/dialog.service';
import {fadeInAnimation} from './shared/animations/fadeIn';
import {DeviceService} from './shared/services/api/device.service';
import {CodeShareService} from './shared/services/api/code-share.service';
import {environment} from '../environments/environment';
import {pageTransition} from './shared/animations/pageTransition';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
  animations: [
    fadeInAnimation(),
    pageTransition()
  ]
})
export class AppComponent implements OnDestroy {
  url$: Observable<string>;
  isSubPage$: Observable<boolean>;
  isErrorPage$: Observable<boolean>;
  titleContent$: Observable<string>;

  showSignInUp = false;

  timerSubscription: Subscription;

  constructor(private router: Router, private route: ActivatedRoute, public dialog: DialogService, private deviceService: DeviceService,
              private codeShare: CodeShareService) {
    this.url$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        return this.route.snapshot.firstChild?.url[0]?.path;
      }),
      startWith('start'),
      shareReplay()
    );

    this.isSubPage$ = this.url$.pipe(
      map((url: string) => {
        return !(url === 'start');
      }),
      shareReplay()
    );

    this.isErrorPage$ = this.url$.pipe(
      map(url => {
        return url === 'error';
      }),
      shareReplay()
    );

    this.titleContent$ = this.url$.pipe(
      map((url) => {
        if (!(url === 'start')) {
          const data = this.route.snapshot.firstChild.data;

          if (!!data.title) {
            return data.title;
          }

          const params = this.route.snapshot.firstChild.params;

          if (!!params.code) {
            return (params.code as string).toUpperCase();
          }
        }

        return null;
      }),
      shareReplay()
    );

    this.timerSubscription = timer(0, environment.pushSendPollingTime).subscribe(() => {
      this.codeShare.getSharedCodes$().subscribe((sharedCodes) => {
        if (sharedCodes.length > 0) {
          this.router.navigateByUrl(`/c/${sharedCodes[0].code}`);
        }
      });
    });

  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
  }
}
