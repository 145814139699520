export class FileHelper {
  public static async toBase64(file: File): Promise<string> {
    const reader = new FileReader();

    const base64Promise = new Promise<string>(resolve => {
      reader.onload = event => resolve(event.target.result as string);
    });

    reader.readAsDataURL(file);

    return await base64Promise;
  }
}
