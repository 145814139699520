import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormControl} from '@angular/forms';
import {AccountService, UserData} from '../../shared/services/account.service';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {UserService} from '../../shared/services/api/user.service';
import {debounceTime, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {QuickshareOption, QuickshareOptionValue, QuickshareService} from '../../shared/services/api/quickshare.service';
import {DialogService} from '../../shared/components/dialog/dialog.service';
import {DeviceData, DeviceService} from '../../shared/services/api/device.service';
import {noRepeatValidator} from '../../shared/helper/validation-helper';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less']
})
export class SettingsComponent implements OnInit, OnDestroy {
  allQuickshareOptions: QuickshareOption[];
  quickShareOptionsForm$: Observable<FormArray>;

  userData$: Observable<UserData>;

  formSubscription: Subscription;

  deviceNameSubject$ = new Subject<string>();
  deviceNameSubscription: Subscription;

  devices$ = new BehaviorSubject<DeviceData[]>([]);

  deviceName: string;

  constructor(private accountService: AccountService, private userService: UserService, private quickshareService: QuickshareService,
              private dialogService: DialogService, public deviceService: DeviceService) {
    this.quickShareOptionsForm$ = this.quickshareService.getOptions$().pipe(
      map(userOptions => {
        return new FormArray([
          new FormControl(userOptions[0]?.value),
          new FormControl(userOptions[1]?.value),
          new FormControl(userOptions[2]?.value),
          new FormControl(userOptions[3]?.value),
          new FormControl(userOptions[4]?.value)
        ], [noRepeatValidator()]);
      }),
      shareReplay()
    );

    this.formSubscription = this.quickShareOptionsForm$.pipe(
      switchMap(form => form.valueChanges.pipe(
        map((formValue) => [form, formValue])
      ))
    ).subscribe(([form, _]: [FormArray, QuickshareOptionValue[]]) => {
      if (!!form.errors?.valueRepeated) {
        const repeatedValue: { doubleKey: string; index: number } = form.errors.valueRepeated;
        form.controls[repeatedValue.index].setValue(undefined, { emitEvent: false });
        form.setErrors({ valueRepeated: repeatedValue });
      }

      const formValue = form.value;

      this.quickshareService.setOptions$(formValue).subscribe(() => {
      }, () => {
        this.dialogService.show('Storing QuickShare options failed.', () => {
        }, undefined, false);
      });
    });

    this.userData$ = this.accountService.currentUser$;

    this.allQuickshareOptions = [{ display: 'Empty', value: undefined }, ...this.quickshareService.getAllOptions()];

    this.deviceNameSubscription = this.deviceNameSubject$.pipe(
      debounceTime(200)
    ).subscribe(deviceName => {
      this.deviceService.setDeviceName(deviceName);
    });

    this.deviceService.getDevices$().pipe(take(1)).subscribe(devices => this.setDevices(devices));

    this.deviceName = this.deviceService.device?.name;
  }

  ngOnInit(): void {
  }

  changeEmail() {
    this.userService.emailChangeRequest$().subscribe(console.log);
  }

  changePassword() {
    this.userService.passwordChangeRequest$().subscribe(console.log);
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
    this.deviceNameSubscription?.unsubscribe();
  }

  removeDevice(device: DeviceData) {
    this.deviceService.unregisterDevice$(device.id).subscribe(() => {
      this.deviceService.getDevices$().pipe(take(1)).subscribe(devices => this.setDevices(devices));
    });
  }

  setDevices(devices: DeviceData[]) {
    const currentValue = this.devices$.getValue();
    currentValue.splice(0, currentValue.length, ...devices);
    this.devices$.next(currentValue);
  }

  deviceTrackBy(index: number, element: DeviceData) {
    return element.id;
  }
}
