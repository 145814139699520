import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isEmpty',
  pure: false
})
export class IsEmptyPipe implements PipeTransform {

  transform(value: FormData|string): boolean {
    if (value instanceof FormData) {
      return value.getAll('file').length <= 1;
    }

    value = value as string;
    return !value || (value.length < 30 && value.indexOf('\n') === -1);
  }

}
