import {environment} from '../../../environments/environment';
import {UserData} from '../services/account.service';

export class StringHelper {
  /**
   * Replaces placeholders in the given content string and fills them with data
   * @param contentRaw The raw content string
   * @param code The code of the data entry
   * @param user The user data (optional)
   */
  public static replaceQuicksharePlaceholder(contentRaw: string, code: string, user?: UserData) {
    const body = `${location.origin}${environment.useRoutingHash ? '/#' : ''}/c/${code}`;
    contentRaw = contentRaw.split('{body}').join(body);

    contentRaw = contentRaw.split('{username}').join(user?.username || 'not logged in');
    contentRaw = contentRaw.split('{email}').join(user?.email || 'email@email.com');

    return contentRaw;
  }
}
