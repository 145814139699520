<div>
  <ng-container *ngIf="codeData$ | async as codeData">
    <h2 class="time">
      Available for
      <b *ngFor="let timePart of availableFor$ | async">
        {{ timePart[1] }}{{ timePart[0] }}
      </b>
    </h2>

    <div class="sort-select" *ngIf="codeData.data.length > 1">
      <app-select [options]="sortSelectOptions" [ngModel]="sortSelectOption$ | async"
                  (ngModelChange)="sortSelectOption$.next($event)"></app-select>
    </div>

    <ng-container *ngIf="codeData | onlyFiles; else notOnlyFiles">
      <div class="code-content" #filesCodeContent
           [class.special-mobile-margin]="codeData.data.length > 1 && codeData | onlyFiles">
        <ng-container *ngFor="let entry of codeData.data | sortByFunction: (sortSelectOption$ | async)">
          <div *ngIf="entry.type === 'FILE'" class="code-type-file"
               [class.single-image]="(entry | isImage) && codeData.data.length === 1">
            <div class="file-display" #fileDisplay>

              <ng-container *ngIf="(entry | isImage) && (codeData.data.length === 1 || previewMultipleImages); else normalFile">
                <div class="image">
                  <img [src]="entry.data" [style.height]="entry.ratio ? fileDisplay.clientWidth * entry.ratio + 'px' : null" />

                  <div class="image-data">
                    <div class="image-type">
                      <span>Type</span>
                      <span>{{ entry | fileEnding }}</span>
                    </div>

                    <div class="image-size">
                      <span>Size</span>
                      <span>{{ entry.size / 1000 | number:'1.0-0' }} KB</span>
                    </div>
                  </div>
                </div>

              </ng-container>
              <ng-template #normalFile>
                <div class="file">
                  <div class="file-preview image-preview" *ngIf="entry | isImage; else normalFile">
                    <img [src]="entry.data" />
                  </div>
                  <ng-template #normalFile>
                    <div class="file-preview">
                      <img src="/assets/icons/icon_file.svg" />
                    </div>
                  </ng-template>

                  <div class="file-type">
                    <span>Type</span>
                    <span>{{ entry | fileEnding }}</span>
                  </div>
                  <div class="file-size">
                    <span>Size</span>
                    <span>{{ entry.size / 1000 | number:'1.0-0' }} KB</span>
                  </div>
                </div>
              </ng-template>

              <a class="download-row" (click)="downloadFile(entry)">
                <img src="/assets/icons/icon_triangle_download.svg" /><br>
                Download
              </a>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #notOnlyFiles>
      <div class="code-content">
        <ng-container *ngFor="let entry of codeData.data">
          <div *ngIf="entry.type === 'TEXT'" class="code-type-text">
            <textarea [(ngModel)]="entry.data" (ngModelChange)="textChanged(codeData.code, entry.codeDataId, $event)"></textarea>
            <p class="save-info">Changes are saved automatically.</p>
            <button class="type1" (click)="downloadText(entry)"><span class="btn-content">Download</span></button>
          </div>

          <div *ngIf="entry.type === 'LINK'" class="code-type-link">
            <a [href]="entry.data" target="_blank">{{ entry.data }}</a>
          </div>
        </ng-container>
      </div>
    </ng-template>


    <div class="footer-row">
      <app-quick-share-bar [code]="codeData.code"></app-quick-share-bar>

      <a class="button type1 zip-button" [href]="codeData | zipUrl" download
         *ngIf="codeData.data.length > 1 && codeData | onlyFiles">
        <span>Download all</span>
        <img src="/assets/icons/icon_triangle_download.svg" />
      </a>
    </div>
  </ng-container>
</div>
