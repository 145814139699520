import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {from, Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';
import {Consts} from '../../consts';
import {FileHelper} from '../../helper/file-helper';

export interface CodeData {
  code: string;
  data: CodeDataEntry[];
  expiresAt: Date;
}

export interface CodeDataEntry {
  codeDataId: string;
  data: string;
  type: 'LINK'|'TEXT'|'FILE';
  size: number;
  ratio?: number;
}

export interface CodeConstraints {
  maxFileSize: number;
  maxNumFiles: number;
}

@Injectable({
  providedIn: 'root'
})
export class CodeService {

  private codeConstraints$: Observable<CodeConstraints>;

  constructor(private http: HttpClient) { }

  /**
   * Gets all data for the given code
   * @param code The code
   */
  public getCode$(code: string): Observable<CodeData> {
    return this.http.get<CodeData>(`${environment.backendUrl}/code/${code}`);
  }

  /**
   * Creates a code for a text
   * @param text The new text
   */
  public postText$(text: string): Observable<CodeData> {
    return this.http.post<CodeData>(`${environment.backendUrl}/code/text`, { text }).pipe(
      catchError(() => of(null))
    );
  }

  /**
   * Creates a code with files
   * @param formData The files as form data
   */
  public postFiles$(formData: FormData) {
    const imageRatioPromises = formData.getAll('file')
      .map(async (file: File, index: number) => {
        if (Consts.imageFileExtensions.findIndex(extension => file.name.toLowerCase().endsWith(extension)) === -1) {
          return undefined;
        }

        const imageElement = document.createElement('img');
        imageElement.src = await FileHelper.toBase64(file);

        await new Promise(resolve => imageElement.onload = () => resolve());

        return { position: index, ratio: imageElement.naturalHeight / imageElement.naturalWidth };
      });

    return from(Promise.all(imageRatioPromises)).pipe(
      switchMap(metadata => {
        formData.delete('metadata');
        formData.append('metadata', JSON.stringify({
          files: metadata.filter(v => !!v)
        }));

        return this.http.post<CodeData>(`${environment.backendUrl}/code/file`, formData).pipe(
          catchError(() => of(null))
        );
      })
    );
  }

  /**
   * Updates text behind a code
   * @param code The code
   * @param codeDataId The data id of the text
   * @param text The updated text
   */
  public updateText$(code: string, codeDataId: string, text: string): Observable<boolean> {
    return this.http.put(`${environment.backendUrl}/code/${code}/${codeDataId}/text`, { text }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  /**
   * Downloads a file of a code data entry
   * @param codeDataEntry The code data entry
   */
  public downloadEntry$(codeDataEntry: CodeDataEntry): Observable<Blob> {
    return this.http.get(codeDataEntry.data, { responseType: 'blob' });
  }

  /**
   * Gets code constraints from server or last cached constraint
   */
  public getConstraints$(): Observable<CodeConstraints> {
    if (!this.codeConstraints$) {
      this.codeConstraints$ = this.http.get<CodeConstraints>(`${environment.backendUrl}/code/constraints`).pipe(
        shareReplay()
      );
    }

    return this.codeConstraints$;
  }
}
