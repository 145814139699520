import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface UserResponse {
  email: string;
  username: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Gets the account data of the logged in user
   */
  public data$(): Observable<UserResponse> {
    return this.httpClient.get<UserResponse>(`${environment.backendUrl}/user`);
  }

  /**
   * Starts an email-change request
   */
  public emailChangeRequest$() {
    return this.httpClient.post(`${environment.backendUrl}/user/email/request`, null);
  }

  /**
   * Starts an password-change request
   */
  public passwordChangeRequest$() {
    return this.httpClient.post(`${environment.backendUrl}/user/password/request`, null);
  }
}
