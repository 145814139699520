import {Injectable} from '@angular/core';
import {AccountService} from '../account.service';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {Observable, of} from 'rxjs';
import {CodeData} from './code.service';
import {DeviceService} from './device.service';

@Injectable({
  providedIn: 'root'
})
export class CodeShareService {

  constructor(private accountService: AccountService, private httpClient: HttpClient, private deviceService: DeviceService) {
  }

  /**
   * Shares a code with a device
   * @param code The code to share
   * @param deviceIds The target device id
   * @param nextLogin Should load the code on next login
   */
  public shareWithDevice(code: string, deviceIds: string[], nextLogin: boolean = false) {
    this.httpClient.post(`${environment.backendUrl}/code/${code}/share/device`, {
      deviceIds,
      nextLogin
    }).subscribe();
  }

  /**
   * Gets all shared codes shared with the next login
   */
  public getLoginSharedCodes$(): Observable<CodeData[]> {
    return this.accountService.signedIn$.pipe(
      take(1),
      switchMap((signedIn) => {
        if (!signedIn) {
          return of([]);
        }

        return this.httpClient.get<CodeData[]>(`${environment.backendUrl}/code/share/login`);
      })
    );
  }

  /**
   * Gets all shared codes of the current instance
   */
  public getSharedCodes$(): Observable<CodeData[]> {
    return this.accountService.signedIn$.pipe(
      take(1),
      switchMap((signedIn) => {
        if (!signedIn) {
          return of([]);
        }

        return this.deviceService.ready$.pipe(
          filter(v => v),
          take(1),
          switchMap(() => {
            if (this.deviceService.device?.id) {
              return this.httpClient.get<CodeData[]>(`${environment.backendUrl}/code/share/device/${this.deviceService.device.id}`).pipe(
              );
            }

            return of(...[]);
          })
        );
      }),
      catchError(() => {
        this.accountService.signOut();
        return of([]);
      })
    );
  }
}
