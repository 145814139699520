<div class="page-container" [@pageTransitionContent]="url$ | async">
  <ng-container *ngIf="isSubPage$ | async; else noSubPage">
    <div class="page-container-content sub-page-active">
      <div class="sub-page" [class.is-error-page]="isErrorPage$ | async">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
      </div>
    </div>
  </ng-container>
  <ng-template #noSubPage>
    <div class="page-container-content">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ng-template>
</div>

<ng-template #contentTemplate><ng-content></ng-content></ng-template>
