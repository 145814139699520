import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RegisterService} from '../../services/api/register.service';
import {DialogService} from '../dialog/dialog.service';
import {AccountService} from '../../services/account.service';
import {CodeShareService} from '../../services/api/code-share.service';
import {Router} from '@angular/router';
import {fadeIn2Animation} from '../../animations/fadeIn';
import {emailOrUserValidator} from '../../helper/validation-helper';

@Component({
  selector: 'app-sign-in-up',
  templateUrl: './sign-in-up.component.html',
  styleUrls: ['./sign-in-up.component.less'],
  animations: [
    fadeIn2Animation()
  ]
})
export class SignInUpComponent implements OnInit {

  @Output() complete = new EventEmitter<void>();

  signInPageEnabled: boolean;
  signInForm: FormGroup;
  signUpForm: FormGroup;

  constructor(private registerService: RegisterService, private accountService: AccountService, private dialog: DialogService,
              private codeShare: CodeShareService, private router: Router) {

    this.signInForm = new FormGroup({
      email: new FormControl('', [Validators.required, emailOrUserValidator()]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      keepSignedIn: new FormControl(false)
    });

    this.signUpForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      username: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z0-9_-]+')]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)]),
      confirm: new FormControl(false, [Validators.requiredTrue])
    });

    this.switchMode(true);
  }

  ngOnInit(): void {
  }

  switchMode(signIn: boolean) {
    this.signInPageEnabled = signIn;
  }

  signIn() {
    Object.values(this.signInForm.controls).forEach((control: FormControl) => {
      control.markAsTouched();
      control.markAsDirty();
    });

    if (this.signInForm.valid) {
      const formValue = this.signInForm.value;
      this.accountService.signIn$(formValue.email, formValue.password, formValue.keepSignedIn).subscribe((success) => {
        if (success) {
          this.codeShare.getLoginSharedCodes$().subscribe((sharedCodes) => {
            if (sharedCodes.length > 0) {
              this.router.navigateByUrl(`/c/${sharedCodes[0].code}`);
            }
          });

          this.complete.emit();
        } else {
          this.signInForm.setErrors({ login: 'login' });

          // this.dialog.show('Signing in failed. The provided login credentials don\'t match or the e-mail address has not yet been confirmed.',
          //   () => null, undefined, false);
        }
      });
    }
  }

  signUp() {
    Object.values(this.signUpForm.controls).forEach((control: FormControl) => {
      control.markAsTouched();
      control.markAsDirty();
    });

    if (this.signUpForm.valid) {
      const formValue = this.signUpForm.value;
      this.registerService.register$(formValue.email, formValue.password, formValue.username).subscribe((success) => {
        if (success) {
          this.dialog.show('Signing up complete. Switch to sign in?', () => this.switchMode(true));
        } else {
          this.dialog.show('Signing up failed.', () => null, undefined, false);
        }
      });
    }
  }
}
