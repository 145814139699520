import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {CodeService} from '../../shared/services/api/code.service';
import {DialogService} from '../../shared/components/dialog/dialog.service';
import {fromEvent, merge, Observable, of, timer} from 'rxjs';
import {catchError, filter, map, shareReplay, take} from 'rxjs/operators';
import {Consts} from '../../shared/consts';
import {Router} from '@angular/router';
import {fadeInAnimation} from '../../shared/animations/fadeIn';
import { LoadingService } from '../../shared/components/loading/loading.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.less'],
  animations: [
    fadeInAnimation(100),
    fadeInAnimation(250, 'fadeInLong'),
  ]
})
export class StartComponent implements OnInit {

  inputValue: FormData|string;
  uploadCode: string;

  placeholder$: Observable<string>;
  addFadeClass$: Observable<boolean>;

  showDropZone$: Observable<boolean>;

  constructor(private codeService: CodeService, private dialog: DialogService, private router: Router, private cdRef: ChangeDetectorRef,
              private loadingService: LoadingService) {
    const baseTimer$ = timer(0, 250).pipe(shareReplay());

    this.placeholder$ = baseTimer$.pipe(
      map(i => i / 6),
      filter(i => Number.isInteger(i)),
      map(i => Consts.placeholders[i % 5])
    );

    this.addFadeClass$ = baseTimer$.pipe(
      map(i => {
        i = i % 6;
        return i >= 1 && i <= 4;
      })
    );

    this.initDropZone();
  }

  /**
   * Register listener for drop zone
   * Shows drop zone when file is dragged over the window
   */
  initDropZone() {
    this.showDropZone$ = merge(
      fromEvent(window, 'dragenter'),
      fromEvent(window, 'dragleave').pipe(filter((event: DragEvent) => event.screenX === 0 && event.screenY === 0))
    ).pipe(
      map((dragEvent: DragEvent) => {
        this.preventEvent(dragEvent);
        return dragEvent.type === 'dragenter';
      })
    );
  }

  /**
   * Handle file dropped in drop zone
   * @param dropEvent The drop event
   */
  fileDropped(dropEvent: DragEvent) {
    const selectedFiles = dropEvent.dataTransfer.files;
    this.addFiles(selectedFiles);

    this.initDropZone();
    return this.preventEvent(dropEvent);
  }

  /**
   * Add files to selected form data
   * @param files The file list to add to form data
   */
  addFiles(files: FileList) {
    if (files.length === 0) {
      return;
    }

    if (!(this.inputValue instanceof FormData)) {
      this.inputValue = new FormData();
    }

    const formData = this.inputValue as FormData;
    this.inputValue = null;
    this.cdRef.detectChanges();

    for (let i = 0; i < files.length; i++) {
      const file = files.item(i);
      formData.append('file', file);
    }

    this.inputValue = formData;
    this.cdRef.detectChanges();
  }

  preventEvent(dropEvent: DragEvent) {
    dropEvent.preventDefault();
    dropEvent.stopPropagation();
    return false;
  }

  ngOnInit(): void {
  }

  /**
   * Submits the data of input value
   */
  submit() {
    if (this.inputValue instanceof FormData) {
      this.loadingService.active$.next(true);

      this.codeService.getConstraints$().pipe(take(1)).subscribe(constraints => {
        const formData = this.inputValue as FormData;
        const allFiles = formData.getAll('file') as File[];
        const totalSize = allFiles.reduce((prevResult, currentFile) => prevResult + currentFile.size, 0);

        if (totalSize > constraints.maxFileSize || allFiles.length > constraints.maxNumFiles) {
          this.loadingService.active$.next(false);
          this.dialog.show(`Maximum file size (~${Math.round(constraints.maxFileSize / 1000 / 1000)} MB) or count (${constraints.maxNumFiles}) exceeded.`, () => null, undefined, false);
          return;
        }

        this.codeService.postFiles$(formData).subscribe((result) => {
          this.loadingService.active$.next(false);

          if (!result) {
            this.dialog.show('File upload failed.', () => null, undefined, false);
            return;
          }

          this.uploadCode = result.code;
          this.inputValue = null;
        });
      });
    } else if (typeof this.inputValue === 'string' && this.inputValue) {
      const uploadTextOrUrl = () => {
        this.loadingService.active$.next(true);

        this.codeService.postText$(this.inputValue as string).subscribe((result) => {
          this.loadingService.active$.next(false);

          if (!result) {
            this.dialog.show('Text/URL upload failed.', () => null, undefined, false);
            return;
          }

          this.uploadCode = result.code;
          this.inputValue = null;
        });
      };


      if (this.inputValue.length === 4) {
        this.codeService.getCode$(this.inputValue).pipe(
          catchError(() => of(null))
        ).subscribe((codeData) => {
          if (codeData) {
            this.router.navigate(['code', this.inputValue]);
          } else {
            this.dialog.show('The entered code does not exist. Do you want to submit your input as text?', () => {
              uploadTextOrUrl();
            }, 'Submit as text');
          }
        });
      } else {
        uploadTextOrUrl();
      }
    }
  }

  handleEnterKey(event: KeyboardEvent): boolean {
    if (event.shiftKey) {
      return true;
    }

    this.submit();
    return false;
  }
}
