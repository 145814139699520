import { Component, OnInit } from '@angular/core';
import { LoadingService } from './loading.service';
import { fadeInAnimation } from '../../animations/fadeIn';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.less'],
  animations: [
    fadeInAnimation(),
  ]
})
export class LoadingComponent implements OnInit {

  constructor(public loadingService: LoadingService) { }

  ngOnInit(): void {
  }

}
