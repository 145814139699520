import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {AccountService} from '../services/account.service';
import {map} from 'rxjs/operators';

/**
 * Checks if a user is logged in. If not the user is redirected to '/'
 */
@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(private account: AccountService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.account.signedIn$.pipe(
      map((signedIn) => {
        if (!signedIn) {
          this.router.navigateByUrl('/');
        }

        return signedIn;
      })
    );
  }
}
