<div class="page-header">

  <button class="type3" style="margin-left: 15px;" [routerLink]="isSubPage ? '/' : '/settings'">
      <span class="btn-content">
        <ng-container *ngIf="isSubPage; else userName">
          <img src="/assets/icons/icon_x_gradient.svg" />
        </ng-container>
        <ng-template #userName>
          <ng-container *ngIf="accountService.currentUserFirstLetter$ | async as firstLetter; else logo">
            <span>{{ firstLetter }}</span>
          </ng-container>
          <ng-template #logo>
            <img style="height: 25px" src="/assets/logo_resmit.svg" />
          </ng-template>
        </ng-template>
      </span>
  </button>

  <ng-container *appLet="titleContent$ | async; let titleContent">
    <ng-container *ngIf="!!titleContent; else noTitleContent">
      <h1>{{ titleContent }}</h1>
    </ng-container>
    <ng-template #noTitleContent>
      <button class="type2 no-title-content" routerLink="support-help">
        <img src="/assets/icons/icon_questionmark.svg" />
      </button>
    </ng-template>
  </ng-container>

  <button class="type2 sign-in-button" (click)="handleSignInOutButton()">
    {{ (accountService.signedIn$ | async) ? 'Sign out' : 'Sign in/up' }}
  </button>
</div>
