import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns all files of form data
 */
@Pipe({
  name: 'getFiles'
})
export class GetFilesPipe implements PipeTransform {

  transform(value: FormData): File[] {
    return ((value?.getAll('file') || []) as File[]);
  }

}
