import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms first char of string to upper case
 */
@Pipe({
  name: 'firstCharUpper'
})
export class FirstCharUpperPipe implements PipeTransform {

  transform(value: string): string {
    return value ? value[0].toUpperCase() : '';
  }

}
