import {animate, state, style, transition, trigger} from '@angular/animations';

/**
 * A simple slideIn/slideOut animation usable on any component
 */
export const slideInAnimation = () => {
  return trigger('slideIn', [
    state('in', style({height: '*', minHeight: '50px'})),
    state('out', style({height: '0px', minHeight: '0px'})),
    transition('in => out', [
      animate('250ms ease')
    ]),
    transition('out => in', [
      animate('150ms 200ms ease')
    ])
  ]);
};
