<div class="page-container-wrapper" [class.dialog-open]="!!dialog.data || showSignInUp">
  <app-header [titleContent$]="titleContent$" (signIn)="showSignInUp = true"
              [isSubPage]="isSubPage$ | async" [@pageTransition]="url$ | async"></app-header>

  <app-content-wrapper>
    <router-outlet></router-outlet>
  </app-content-wrapper>

  <app-footer></app-footer>
  <app-consent></app-consent>

  <app-sign-in-up *ngIf="showSignInUp" [@fadeIn] (complete)="showSignInUp = false"></app-sign-in-up>
  <app-dialog *ngIf="!!dialog.data" [@fadeIn]></app-dialog>

  <app-loading></app-loading>
</div>
