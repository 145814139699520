import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {AuthService, LoginResponse} from './api/auth.service';
import {Consts} from '../consts';
import {Router} from '@angular/router';
import {UserService} from './api/user.service';
import {AuthTokenService} from './auth-token.service';

export interface UserData {
  username: string;
  email: string;
  deviceId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public currentUser$: BehaviorSubject<UserData> = new BehaviorSubject<UserData>(null);
  public currentUserFirstLetter$: Observable<string>;
  public signedIn$: Observable<boolean>;

  constructor(private authService: AuthService, private router: Router, private userService: UserService,
              private authTokenService: AuthTokenService) {
    this.signedIn$ = this.currentUser$.pipe(map(u => !!u));

    this.currentUserFirstLetter$ = this.currentUser$.pipe(
      map(user => user && user.username && user.username[0]?.toUpperCase())
    );

    const accountJson = localStorage.getItem(Consts.accountLocalStorage);

    if (!!accountJson) {
      this.currentUser$.next(JSON.parse(accountJson));
    }

    this.authTokenService.tokenWrongEvent.subscribe(() => {
      this.signOut();
    });
  }

  /**
   * Starts a login for the current instance
   * @param email The email/username for login
   * @param password The password
   * @param remember Define if the data should get stored in local storage
   */
  signIn$(email: string, password: string, remember: boolean): Observable<boolean> {
    return this.authService.login$(email, password).pipe(
      tap(loginResponse => this.authTokenService.setData(loginResponse, remember)),
      switchMap((loginResponse) => this.userService.data$().pipe(
        map(userResponse => [userResponse, loginResponse])
      )),
      tap(([userResponse, loginResponse]: [UserData, LoginResponse]) => {
        this.currentUser$.next({
          email: userResponse.email,
          username: userResponse.username,
          deviceId: loginResponse.deviceId
        });

        if (remember) {
          localStorage.setItem(Consts.accountLocalStorage, JSON.stringify(this.currentUser$.value));
        } else {
          localStorage.removeItem(Consts.accountLocalStorage);
        }
      }),
      map(() => true),
      catchError(() => {
        this.authTokenService.resetData();
        return of(false);
      })
    );
  }

  /**
   * Deletes local stored user data
   */
  signOut() {
    this.currentUser$.next(null);
    localStorage.removeItem(Consts.accountLocalStorage);
    this.authTokenService.resetData();
    this.router.navigateByUrl('/');
  }
}
