<div class="container" #startPageContainer>
  <div class="content-center">
    <ng-container *ngIf="uploadCode; else noCode">
      <div class="code-box" [@fadeInLong]>
        <h1>{{ uploadCode }}</h1>
        <app-quick-share-bar [code]="uploadCode"></app-quick-share-bar>
      </div>
    </ng-container>
    <ng-template #noCode>
      <h1>Hi</h1>
    </ng-template>
  </div>

  <div class="main-input" [class.not-empty]="!(inputValue | isEmpty)">
    <ng-container *ngIf="placeholder$ | async as placeholder">
      <app-file-input [maxHeight]="startPageContainer.clientHeight - 90 + 'px'" [placeholder]="placeholder"
                      [(ngModel)]="inputValue" *ngIf="inputValue | isFormData"></app-file-input>
      <textarea [class.empty]="inputValue | isEmpty" autosize rows="1" [placeholder]="placeholder" *ngIf="!(inputValue | isFormData)"
                [style.maxHeight]="startPageContainer.clientHeight - 90 + 'px'" [(ngModel)]="inputValue"
                [class.fade]="addFadeClass$ | async" (keydown.enter)="handleEnterKey($event)"></textarea>
    </ng-container>

    <app-file-button [inputButton]="true" [normalButton]="!!inputValue"
                     (filesSelected)="addFiles($event)" (clicked)="submit()"></app-file-button>

  </div>
</div>

<div class="dropzone" *ngIf="showDropZone$ | async" [@fadeIn]>
  <div class="bg2"></div>
  <div class="bg1"></div>

  <div class="content" (drop)="fileDropped($event)" (dragover)="preventEvent($event)">
    <h1>Drop it!</h1>
  </div>
</div>
