import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { asapScheduler } from 'rxjs';

@Component({
  selector: 'app-file-button',
  templateUrl: './file-button.component.html',
  styleUrls: ['./file-button.component.less']
})
export class FileButtonComponent implements OnInit {
  @Output() filesSelected = new EventEmitter<FileList>();
  @Output() clicked = new EventEmitter<void>();

  @Input() normalButton: boolean;
  @Input() inputButton: boolean;

  render = true;

  constructor() { }

  ngOnInit(): void {
  }

  fileSelected(event: Event) {
    const eventTarget = event.target as HTMLInputElement;
    this.filesSelected.emit(eventTarget.files);

    this.render = false;
    asapScheduler.schedule(() => this.render = true, 5);
  }

  handleClick() {
    if (this.normalButton) {
      this.clicked.emit();
      return false;
    }
  }
}
