import {Pipe, PipeTransform} from '@angular/core';
import {CodeDataEntry} from '../../shared/services/api/code.service';
import {Consts} from '../../shared/consts';

/**
 * Checks if the given CodeDataEntry is an image file
 */
@Pipe({
  name: 'isImage'
})
export class IsImagePipe implements PipeTransform {

  transform(entry: CodeDataEntry): unknown {
    return entry.type === 'FILE' && Consts.imageFileExtensions.findIndex(extension => entry.data.toLowerCase().endsWith(extension)) !== -1;
  }

}
