import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {StartComponent} from './pages/start/start.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {AboutComponent} from './pages/about/about.component';
import {SupportHelpComponent} from './pages/support-help/support-help.component';
import {TermsOfServiceComponent} from './pages/term-of-service/terms-of-service.component';
import {PrivacyPolicyComponent} from './pages/privacy-policy/privacy-policy.component';
import {LegalNoticeComponent} from './pages/legal-notice/legal-notice.component';
import {FooterComponent} from './shared/components/layout/footer/footer.component';
import {HeaderComponent} from './shared/components/layout/header/header.component';
import {SignInUpComponent} from './shared/components/sign-in-up/sign-in-up.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ConsentComponent} from './shared/components/layout/consent/consent.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {SelectComponent} from './shared/components/select/select.component';
import {DialogComponent} from './shared/components/dialog/dialog.component';
import {QuickShareBarComponent} from './shared/components/quick-share-bar/quick-share-bar.component';
import {CodeComponent} from './pages/code/code.component';
import {ErrorComponent} from './pages/error/error.component';
import {FileSaverModule} from 'ngx-filesaver';
import {FileInputComponent} from './shared/components/file-input/file-input.component';
import {FileButtonComponent} from './shared/components/file-button/file-button.component';
import {AutosizeModule} from 'ngx-autosize';
import {SanitizeUrlPipe} from './shared/pipes/sanitize-url.pipe';
import {BuildQuickshareUrlPipe} from './shared/components/quick-share-bar/build-quickshare-url.pipe';
import {AttrDirective} from './shared/directives/attr.directive';
import {GetFilesPipe} from './shared/pipes/get-files.pipe';
import {IsImagePipe} from './pages/code/is-image.pipe';
import {FileEndingPipe} from './pages/code/file-ending.pipe';
import {IsFormDataPipe} from './pages/start/is-form-data.pipe';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ZipUrlPipe} from './pages/code/zip-url.pipe';
import {IsEmptyPipe} from './pages/start/is-empty.pipe';
import {AuthTokenInterceptorService} from './shared/services/auth-token-interceptor.service';
import {FirstCharUpperPipe} from './shared/components/quick-share-bar/first-char-upper.pipe';
import {SortByFunctionPipe} from './pages/code/sort-by-function.pipe';
import {LetDirective} from './shared/directives/let.directive';
import {OnlyFilesPipe} from './pages/code/only-files.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SmoothHeightComponent } from './shared/components/smooth-height/smooth-height.component';
import { ContentWrapperComponent } from './shared/components/layout/content-wrapper/content-wrapper.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { FileNamePipe } from './pages/code/file-name.pipe';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    SettingsComponent,
    AboutComponent,
    SupportHelpComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    LegalNoticeComponent,
    FooterComponent,
    HeaderComponent,
    SignInUpComponent,
    ConsentComponent,
    SelectComponent,
    DialogComponent,
    QuickShareBarComponent,
    CodeComponent,
    ErrorComponent,
    FileInputComponent,
    FileButtonComponent,
    SanitizeUrlPipe,
    BuildQuickshareUrlPipe,
    AttrDirective,
    GetFilesPipe,
    IsImagePipe,
    FileEndingPipe,
    IsFormDataPipe,
    ZipUrlPipe,
    IsEmptyPipe,
    FirstCharUpperPipe,
    SortByFunctionPipe,
    LetDirective,
    OnlyFilesPipe,
    SmoothHeightComponent,
    ContentWrapperComponent,
    LoadingComponent,
    FileNamePipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FileSaverModule,
    AutosizeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptorService, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
