import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isFormData'
})
export class IsFormDataPipe implements PipeTransform {

  transform(inputValue: FormData | string): boolean {
    return inputValue instanceof FormData;
  }

}
