import { Pipe, PipeTransform } from '@angular/core';

/**
 * Sorts the given array by a custom function
 */
@Pipe({
  name: 'sortByFunction'
})
export class SortByFunctionPipe implements PipeTransform {

  transform(values: any[], sortFunction: (v: any) => any): any[] {
    return values.slice(0).sort((v1, v2) => {
      const v1Condition = sortFunction(v1);
      const v2Condition = sortFunction(v2);

      return v1Condition === v2Condition ? 0 : v1Condition < v2Condition ? -1 : 1;
    });
  }

}
