import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
  deviceId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Handles a login
   * @param email The users email or username
   * @param password The password
   */
  public login$(email: string, password: string): Observable<LoginResponse> {
    return this.httpClient.post<LoginResponse>(`${environment.backendUrl}/auth/login`, { email, password });
  }

  /**
   * Creates a new token with a refresh token
   * @param refreshToken The refresh token
   */
  public refreshToken$(refreshToken: string): Observable<Partial<LoginResponse>> {
    return this.httpClient.post<LoginResponse>(`${environment.backendUrl}/auth/token`, {
      refresh_token: refreshToken
    });
  }
}
