import { Component, OnInit } from '@angular/core';
import { Consts } from '../../../consts';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.less']
})
export class ConsentComponent implements OnInit {
  showConsent: boolean;

  constructor() {
    this.showConsent = !localStorage.getItem(Consts.consentLocalStorage);
  }

  ngOnInit(): void {
  }

  agreeConsent() {
    localStorage.setItem(Consts.consentLocalStorage, 'true');
    this.showConsent = false;
  }
}
