import { Pipe, PipeTransform } from '@angular/core';
import { CodeDataEntry } from '../../shared/services/api/code.service';

@Pipe({
  name: 'fileEnding'
})
export class FileEndingPipe implements PipeTransform {

  transform(value: CodeDataEntry): string {
    return '.' + value?.data?.split('.')?.reverse()[0].toLowerCase();
  }
}
