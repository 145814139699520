import {FormArray, FormControl, ValidatorFn, Validators} from '@angular/forms';

export function noRepeatValidator(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } => {
    const formValue: { key: string }[] = formArray.value;

    const formValueKeys = formValue.map(v => v?.key).filter(v => !!v);

    const doubleKey = formValueKeys.find((key, index, array) => {
      return array.lastIndexOf(key) !== index;
    });

    if (!!doubleKey) {
      return {
        valueRepeated: {
          doubleKey,
          index: formValue.map(v => v?.key).lastIndexOf(doubleKey)
        }
      };
    }

    return undefined;
  };
}

export function emailOrUserValidator(): ValidatorFn {
  return (formControl: FormControl): { [key: string]: any } => {
    const emailResult = Validators.email(formControl);

    if (!emailResult) {
      return undefined;
    }

    const patternResult = Validators.pattern('[a-zA-Z0-9_-]+')(formControl);

    if (!patternResult) {
      return undefined;
    }

    return {format: 'No e-mail or username'};
  };
}
