import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountService} from '../../../services/account.service';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  @Output() signIn = new EventEmitter<null>();

  @Input() titleContent$: Observable<string>;
  @Input() isSubPage: boolean;

  constructor(public accountService: AccountService) { }

  ngOnInit(): void {
  }

  handleSignInOutButton() {
    this.accountService.signedIn$.pipe(take(1)).subscribe(signedIn => {
      if (signedIn) {
        this.accountService.signOut();
      } else {
        this.signIn.emit();
      }
    });
  }
}
