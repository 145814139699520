<div class="quickshare-bar" [class.device-list-active]="selectDeviceActive">
  <ng-container *ngFor="let option of options$ | async">
    <div class="quickshare-option" *ngIf="!!option.value.action || option.value.key === 'PUSH_SEND'; else actionUrl"
         (click)="option.value.key === 'PUSH_SEND' ? openPushSend() : option.value.action(code, injector)"
         [class.option-inactive]="!(option.value.key === 'PUSH_SEND' && selectDeviceActive)">
      <div [class.pushsend-active]="option.value.key === 'PUSH_SEND' && selectDeviceActive">
        <div class="quickshare-preview"
             [innerHTML]="option.value.key === 'PUSH_SEND' && selectDeviceActive ?
              '<img src=\'/assets/icons/icon_pushsend_active.svg\' />' : option.value.preview">
        </div>
        <div class="quickshare-text">{{ option.display }}</div>
      </div>
    </div>
    <ng-template #actionUrl>
      <a class="quickshare-option" [href]="option | buildQuickshareUrl:code | async" [attr]="option.value.attributes"
         [code]="code" [class.option-inactive]="!(option.value.key === 'PUSH_SEND' && selectDeviceActive)">
        <span class="quickshare-preview" [innerHTML]="option.value.preview"></span>
        <span class="quickshare-text">{{ option.display }}</span>
      </a>
    </ng-template>
  </ng-container>

  <div class="push-send-menu" [class.out]="!selectDeviceActive" [@slideIn]="selectDeviceActive ? 'in' : 'out'">
    <div class="device-list">
      <div class="device" *ngFor="let device of devices$ | async" (click)="share(device.id)">
        <span class="device-short">{{ device.name | firstCharUpper }}</span>
        {{ device.name }}
      </div>
    </div>

    <div class="button-right" (click)="share()">
      <img src="/assets/icons/icon_triangle_action.svg" />
      <span>Future device</span>
    </div>
  </div>

  <div class="inactive-layer" [@fadeInState]="selectDeviceActive ? 'in' : 'out'"></div>
</div>
