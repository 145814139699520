<div class="consent" *ngIf="showConsent">
  <p class="text">
    You acknowledge and agree that we may use cookies and third-party services for development and research purposes
    to better understand how our services being used. You can easily withdraw you consent and opt-out of cookies
    that are not required for our website or services to work in our <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a> section. By
    using our website or services, you acknowledge and agree to our <a routerLink="/terms-of-service" target="_blank">Terms of Service</a> and
    <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>.
  </p>

  <button class="type1" (click)="agreeConsent()">Agree</button>
</div>
