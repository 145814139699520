import {animate, query, state, style, transition, trigger} from '@angular/animations';

/**
 * A simple fadeIn/fadeOut animation usable on any component
 * @param duration The duration
 * @param name A custom name if multiple usage in one component is needed
 */
export const fadeInAnimation = (duration: number = 250, name: string = 'fadeIn') => {
  return trigger(name, [
    state('*', style({opacity: 1})),
    transition(':enter', [
      style({opacity: 0}),
      animate(duration)
    ]),
    transition(':leave', [
      animate(duration, style({opacity: 0}))
    ])
  ]);
};

/**
 * A simple fadeIn/fadeOut animation with custom state
 */
export const fadeInStateAnimation = () => {
  return trigger('fadeInState', [
    state('in', style({opacity: .5, display: 'block'})),
    state('out', style({opacity: 0, display: 'none'})),
    transition('* => *', [
      style({display: 'block'}),
      animate('250ms ease')
    ])
  ]);
};

/**
 * A simple fadeIn/fadeOut animation usable on any component
 * With special hide/show behavior
 * @param name A custom name if multiple usage in one component is needed
 */
export const fadeIn2Animation = (name: string = 'fadeIn2') => {
  return trigger(name, [
    transition('* => *', [
      // Hide new element
      query(':enter', [
          style({ opacity: 0, position: 'absolute', top: 0, overflow: 'hidden' })
      ], { optional: true }),

      // Animate old element
      query(':leave', [
        style({ opacity: 1 }),
        animate('125ms ease', style({ opacity: 0 })),
        style({ position: 'absolute', overflow: 'hidden' })
      ], { optional: true }),

      // Animate new element
      query(':enter', [
        style({ opacity: 0, position: 'initial' }),
        animate('125ms ease', style({ opacity: 1 }))
      ], { optional: true }),

      // Fix position initial for IE, Edge and Safari
      query(':enter', [
        style({ position: 'initial' })
      ], { optional: true }),
    ])
  ]);
};
