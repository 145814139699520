import { Pipe, PipeTransform } from '@angular/core';
import {CodeData} from '../../shared/services/api/code.service';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

/**
 * Creates a url for zip-download
 */
@Pipe({
  name: 'zipUrl'
})
export class ZipUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: CodeData): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(`${environment.backendUrl}/code/${data.code}/zip`);
  }

}
