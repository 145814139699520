import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {

  originUrl: string;

  constructor() {
    this.originUrl = location.origin;
  }

  ngOnInit(): void {
  }

}
