<div *ngIf="userData$ | async as userData">
  <h1>Account</h1>

  <div style="margin-bottom: 15px">
    <h2>Username</h2>
    <p>{{ userData.username }}</p>
  </div>

  <div style="margin-bottom: 15px">
    <h2>E-mail</h2>
    <p>{{ userData.email }}</p>
    <button class="type1" (click)="changeEmail()">Change e-mail</button>
  </div>

  <div style="margin-bottom: 30px">
    <h2>Password</h2>
    <button class="type1" (click)="changePassword()">Change password</button>
  </div>

  <h1>QuickShare</h1>

  <p>
    QuickShare enables you to effortlessly send a code to any of your other devices via PushSend or to someone else through your favourite means of communication. Customize your QuickShare options below for even greater convenience.
  </p>

  <ng-container *ngIf="quickShareOptionsForm$ | async as form">
    <div class="quickshare-edit-options" [style.marginBottom]="form.valid ? '30px' : '15px'">
      <div class="quickshare-option" *ngFor="let quickShareOptionControl of form.controls">
        <div class="quickshare-preview" [innerHTML]="quickShareOptionControl.value?.preview"></div>
        <app-select [formControl]="quickShareOptionControl" [options]="allQuickshareOptions"
                    placeholder="QuickShare option"></app-select>
      </div>
    </div>

    <p class="error" style="margin-bottom: 30px" *ngIf="form.errors?.valueRepeated">
      You may only select a certain QuickShare option once.
    </p>
  </ng-container>

  <h1>Device</h1>

  <p>
    Name this device so that you can easily recognize it every time you use PushSend.
  </p>

  <input class="device-name" type="text" placeholder="Device name" [ngModel]="deviceName"
         (ngModelChange)="deviceNameSubject$.next($event)" />

  <p>
    Remove other devices that are inactive or no longer in use. You are automatically signed out on the device you remove.
  </p>

  <div class="device-list" *ngIf="devices$ | async as devices">
    <div class="device-bubble" *ngFor="let device of devices; trackBy: deviceTrackBy" (click)="removeDevice(device)">
      {{ device.name }}
      <img src="/assets/icons/icon_x.svg" />
    </div>
  </div>
</div>
