import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {StringHelper} from '../../helper/string-helper';
import {AccountService} from '../../services/account.service';
import {map, shareReplay} from 'rxjs/operators';
import {Observable} from 'rxjs';

/**
 * Replaces all placeholders in the string and creates safe html string
 */
@Pipe({
  name: 'buildQuickshareUrl'
})
export class BuildQuickshareUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer, private accountService: AccountService) { }

  transform(value: any, code: string): Observable<SafeUrl> {
    if (!value || !value.value || !value.value.url) {
      return null;
    }

    return this.accountService.currentUser$.pipe(
      map(user => {
        const url = StringHelper.replaceQuicksharePlaceholder(value.value.url, code, user);
        return this.sanitizer.bypassSecurityTrustUrl(url);
      }),
      shareReplay()
    );
  }

}
