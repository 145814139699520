import {Component, ElementRef, forwardRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export interface SelectOption {
  display: string;
  value: any;
}

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.less'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SelectComponent), multi: true}
  ]
})
export class SelectComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() options: SelectOption[];

  selected: SelectOption;

  open: boolean;

  @ViewChild('selectElement', { static: true }) selectElementRef: ElementRef;

  private changeFunction: (newValue: any) => void;

  constructor() {
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.changeFunction = fn;
  }

  registerOnTouched(fn: any): void {

  }

  writeValue(obj: any): void {
    this.selected = this.options.find(o => o.value === obj);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.selectElementRef.nativeElement.contains(event.target)) {
      this.open = false;
    }
  }

  openSelect(event: MouseEvent) {
    if (this.open) {
      this.open = false;
      return;
    }

    this.open = true;
  }

  selectOption(option: SelectOption) {
    this.selected = option;
    this.open = false;

    if (this.changeFunction) {
      this.changeFunction(this.selected.value);
    }
  }
}
