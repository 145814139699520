import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileName'
})
export class FileNamePipe implements PipeTransform {

  transform(value: string): string {
    const valueSplitted = value.split('/');
    return valueSplitted[valueSplitted.length - 1];
  }

}
