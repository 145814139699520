import {Component, OnInit} from '@angular/core';
import {pageTransition} from '../../../animations/pageTransition';
import {Observable} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map, shareReplay, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-content-wrapper',
  templateUrl: './content-wrapper.component.html',
  styleUrls: ['./content-wrapper.component.less'],
  animations: [
    pageTransition('pageTransitionContent', true),
  ]
})
export class ContentWrapperComponent implements OnInit {
  url$: Observable<string>;
  isSubPage$: Observable<boolean>;
  isErrorPage$: Observable<boolean>;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.url$ = this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        return this.route.snapshot.firstChild?.url[0]?.path;
      }),
      startWith('start'),
      shareReplay()
    );

    this.isSubPage$ = this.url$.pipe(
      map((url: string) => {
        return !(url === 'start');
      }),
      shareReplay()
    );

    this.isErrorPage$ = this.url$.pipe(
      map(url => {
        return url === 'error';
      }),
      shareReplay()
    );
  }

  ngOnInit(): void {
  }

}
