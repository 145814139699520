<div class="file-input" [style.maxHeight]="maxHeight" *ngIf="formData | getFiles as files">
  <ng-container *ngIf="files.length === 0; else filesPreview">
    <label for="custom_file" class="placeholder">{{placeholder}}</label>
    <input id="custom_file" type="file" [multiple]="true" (change)="fileSelected($event)" />
  </ng-container>
  <ng-template #filesPreview>
    <div class="file-list">
      <div class="file-bubble" *ngFor="let file of files" (click)="removeFile(file)" [@fadeIn]>
        {{ file.name }}
        <img src="/assets/icons/icon_x.svg" />
      </div>
      <app-file-button (filesSelected)="addFiles($event)"></app-file-button>
    </div>
  </ng-template>
</div>
