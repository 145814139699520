<div class="background-layer" (click)="dialog.hide()"></div>

<div class="modal">
  <div class="content">
    {{ dialog.data.message }}
  </div>

  <div class="buttons" [class.single-button]="!dialog.data.showCancel">
    <button class="dialog-button" (click)="dialog.hide()" *ngIf="dialog.data.showCancel">
      <span class="btn-content">
        <img src="assets/icons/icon_x.svg" /><br>
        Cancel
      </span>
    </button>
    <button class="dialog-button" (click)="dialog.complete()">
      <span class="btn-content">
        <img src="assets/icons/icon_triangle_action.svg" /><br>
        {{ dialog.data.okayText }}
      </span>
    </button>
  </div>
</div>
