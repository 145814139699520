<div class="container">
  <div class="content-center">
    <h1>:(</h1>
  </div>

  <div class="main-content">
    <h1>Something went wrong ...</h1>
    <p>
      Try reloading the page or go back to <a [href]="originUrl">{{ originUrl }}</a>.<br>
      Contact support, if this issue persists.
    </p>
  </div>
</div>
