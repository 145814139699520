import { Injectable } from '@angular/core';

export interface DialogData {
  message: string;
  callback: () => void;
  okayText: string;
  showCancel: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  public data: DialogData;

  constructor() { }

  /**
   * Shows a dialog with a custom message
   * @param message The dialog message
   * @param callback A callback function to be executed on user interaction
   * @param okayText A custom text displayed in okay button
   * @param showCancel Disable cancel button
   */
  show(message: string, callback: () => void, okayText: string = 'Okay', showCancel: boolean = true) {
    this.data = { message, callback, okayText, showCancel };
  }

  /**
   * Hides the current dialog
   */
  hide() {
    this.data = null;
  }

  /**
   * Completes the current dialog
   */
  complete() {
    this.data.callback();
    this.hide();
  }
}
