import {ChangeDetectorRef, Component, forwardRef, Input, OnInit, TrackByFunction} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {fadeInAnimation} from '../../animations/fadeIn';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.less'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FileInputComponent), multi: true}
  ],
  animations: [
    fadeInAnimation(100)
  ]
})
export class FileInputComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() maxHeight: string;

  private changeFunction: (newValue: any) => void;
  formData: FormData;

  constructor(private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.changeFunction = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: FormData): void {
    this.formData = obj;
    this.cdRef.detectChanges();
  }

  private appendToFormData(selectedFiles: FileList) {
    if (!selectedFiles) {
      return;
    }

    if (!this.formData) {
      this.formData = new FormData();
    }

    const formData = this.formData;
    this.formData = null;
    this.cdRef.detectChanges();

    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles.item(i);
      formData.append('file', file);
    }

    this.formData = formData;
    this.cdRef.detectChanges();

    if (this.changeFunction) {
      this.changeFunction(this.formData);
    }
  }

  fileSelected(event: Event) {
    const eventTarget = event.target as HTMLInputElement;
    this.appendToFormData(eventTarget.files);
  }

  addFiles(files: FileList) {
    this.appendToFormData(files);
  }

  removeFile(fileToRemove: File) {
    let formData = this.formData;
    this.formData = null;
    this.cdRef.detectChanges();

    let allFiles = formData.getAll('file') as File[];
    allFiles = allFiles.filter(file => file !== fileToRemove);

    formData.delete('file');

    if (allFiles.length === 0) {
      formData = null;
    } else {
      allFiles.forEach(file => {
        formData.append('file', file);
      });
    }

    this.formData = formData;
    this.cdRef.detectChanges();

    if (this.changeFunction) {
      this.changeFunction(this.formData);
    }
  }
}
