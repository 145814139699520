<div class="background-layer" (click)="complete.emit()"></div>

<div class="modal">
  <div>
    <button class="page-switch-button" [class.active]="signInPageEnabled" (click)="switchMode(true)">
      <span class="btn-content">Sign in</span>
    </button>
    <button class="page-switch-button" [class.active]="!signInPageEnabled" (click)="switchMode(false)">
      <span class="btn-content">Sign up</span>
    </button>
  </div>

  <div class="content">
    <app-smooth-height [trigger]="signInPageEnabled" [@fadeIn2]="signInPageEnabled">
      <div *ngIf="signInPageEnabled; else signUpFormTemplate">
        <form [formGroup]="signInForm" [noValidate]="true" (ngSubmit)="signIn()">

          <ng-container *ngIf="signInForm.get('email') as usernameControl">
            <input style="margin-top: 30px" type="text" [formControl]="usernameControl" placeholder="E-mail/Username"
                   [class.error]="!usernameControl.pristine && usernameControl.touched && (usernameControl.errors || signInForm.errors?.login)"/>

            <p class="error" *ngIf="!usernameControl.pristine && usernameControl.touched && usernameControl.errors">
              Please enter a valid e-mail address or username.
            </p>
          </ng-container>

          <ng-container *ngIf="signInForm.get('password') as passwordControl">
            <input style="margin-top: 15px" type="password" [formControl]="passwordControl" placeholder="Password"
                   [class.error]="!passwordControl.pristine && passwordControl.touched && (passwordControl.errors || signInForm.errors?.login)"/>

            <p class="error" *ngIf="!passwordControl.pristine && passwordControl.touched && passwordControl.errors">
              The password must at least contain 8 characters.
            </p>
          </ng-container>

          <p class="error" *ngIf="signInForm.errors?.login">
            Signing in failed. The provided login credentials don't match or the e-mail address has not yet been confirmed.
          </p>

          <label class="checkbox" style="margin-top: 15px">Keep me signed in.
            <input type="checkbox" checked formControlName="keepSignedIn">
            <span class="checkmark"></span>
          </label>

          <div style="text-align: center">
            <button type="submit" class="type1" style="margin: 30px 0;">Submit</button>
          </div>
        </form>
      </div>

      <ng-template #signUpFormTemplate>
        <div>
          <form [formGroup]="signUpForm" [noValidate]="true" (ngSubmit)="signUp()">
            <ng-container *ngIf="signUpForm.get('username') as usernameControl">
              <input style="margin-top: 30px" type="text" [formControl]="usernameControl" placeholder="Username"
                     [class.error]="!usernameControl.pristine && usernameControl.touched && usernameControl.errors"/>
              <p class="error" *ngIf="!usernameControl.pristine && usernameControl.touched && usernameControl.errors">
                The username may only contain alphanumeric characters and "-" as well as "_" signs.
              </p>
            </ng-container>

            <ng-container *ngIf="signUpForm.get('email') as emailControl">
              <input style="margin-top: 15px" type="text" [formControl]="emailControl" placeholder="E-mail"
                     [class.error]="!emailControl.pristine && emailControl.touched && emailControl.errors"/>

              <p class="error" *ngIf="!emailControl.pristine && emailControl.touched && emailControl.errors">
                Please enter a valid e-mail address.
              </p>
            </ng-container>

            <ng-container *ngIf="signUpForm.get('password') as passwordControl">
              <input style="margin-top: 15px" type="password" [formControl]="passwordControl" placeholder="Password"
                     [class.error]="!passwordControl.pristine && passwordControl.touched && passwordControl.errors"/>

              <p class="error" *ngIf="!passwordControl.pristine && passwordControl.touched && passwordControl.errors">
                The password must at least contain 8 characters.
              </p>
            </ng-container>

            <label class="checkbox" [class.error]="!confirmControl.pristine && confirmControl.touched && confirmControl.errors"
                   style="margin-top: 15px" *ngIf="signUpForm.get('confirm') as confirmControl">
              I hereby confirm that I am at least 16 years old and agree to the <a routerLink="/terms-of-service"
                                                                                   target="_blank">Terms of Service</a>
              and the <a routerLink="/privacy-policy" target="_blank">Privacy Policy</a>.
              <input type="checkbox" checked [formControl]="confirmControl">
              <span class="checkmark"></span>
            </label>

            <div style="text-align: center">
              <button type="submit" class="type1" style="margin: 30px 0;">Submit</button>
            </div>
          </form>
        </div>
      </ng-template>
    </app-smooth-height>

  </div>
</div>
