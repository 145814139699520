<p>
<section style="width: 100%; height: 100vh; display: flex; align-items: center; justify-content: center;">
	<div style="text-align: center;">
		<h1 style="font-size: 80px;"><strong style="background-image: linear-gradient(90deg, #5C77FC, #5CB7FC); -webkit-text-fill-color: rgba(0, 0, 0, 0); -webkit-background-clip: text; font-weight: 700;">Resmit</strong></h1>
		<p>File transfer conveniently simplified</p>
	</div>
</section>
<section style="width: 100%; min-height: 100vh; display: flex; align-items: center; justify-content: center;">
	<div style="text-align: center;">
		<h1 style="max-width: 935px; text-align: left;"><strong style="background-image: linear-gradient(90deg, #5C77FC, #5CB7FC); -webkit-text-fill-color: rgba(0, 0, 0, 0); -webkit-background-clip: text; font-weight: 700;">What</strong> <span style="color: #A0A0A0;"> is Resmit?</span></h1>
		<p style="max-width: 935px; text-align: left;">Resmit is a primarily web-based service allowing you to transfer files and links efficiently by minimizing the required steps. Our service is available on all kinds of devices, irrespective of their type or operating system. You can access submitted files and links simply by entering a four-digit code instead of a cumbersome, long link.</p>
	</div>
</section>
<section style="width: 100%; min-height: 100vh; display: flex; align-items: center; justify-content: center;">
	<div style="text-align: center;">
		<h1 style="max-width: 935px; text-align: right;"><strong style="background-image: linear-gradient(90deg, #5C77FC, #5CB7FC); -webkit-text-fill-color: rgba(0, 0, 0, 0); -webkit-background-clip: text; font-weight: 700;">Who</strong> <span style="color: #A0A0A0;"> is behind Resmit?</span></h1>
		<p style="max-width: 935px; text-align: left;">In 2019, we came together as a young, dynamic team with one vision in mind: To improve online data transfer. Our goal is to provide a modern, intuitive solution perfectly tailored to our customers&#x2019; individual needs. In the course of our development, we have always been guided by the idea to take feedback very seriously and adjust our platform accordingly.</p>
		<div style="max-width: 935px; display: flex; align-items: flex-start; justify-content: center; flex-wrap: wrap;">
			<div style="width: calc((100% - 30px) / 3); min-width: 150px; text-align: center; box-sizing: border-box; padding: 15px; flex-grow: 1; flex-basis: auto;">
				<img src="https://yoktolab.com/resmitAssets/alex.png" style="width: 100%; max-width: calc((935px - 30px) / 3); background-color: #EAEAEA; border-radius: 50%; object-fit: contain;">
				<p><strong stlye="font-weight: 700;">Alexander</strong> Eissler</p>
				<p style="color: #A0A0A0;">charismatic cool-keeper, organized perfectionist, passionate to-do-list-finisher</p>
			</div>
			<div style="width: calc((100% - 30px) / 3); min-width: 150px; text-align: center; box-sizing: border-box; padding: 15px; flex-grow: 1; flex-basis: auto;">
				<img src="https://yoktolab.com/resmitAssets/akos.png" style="width: 100%; max-width: calc((935px - 30px) / 3); background-color: #EAEAEA; border-radius: 50%; object-fit: contain;">
				<p><strong stlye="font-weight: 700;">&#xC1;kos</strong> Filakovszky</p>
				<p style="color: #A0A0A0;">creative master-mind, out-of-the-box-thinker, modern-design-lover</p>
			</div>
			<div style="width: calc((100% - 30px) / 3); min-width: 150px; text-align: center; box-sizing: border-box; padding: 15px; flex-grow: 1; flex-basis: auto;">
				<img src="https://yoktolab.com/resmitAssets/damian.png" style="width: 100%; max-width: calc((935px - 30px) / 3); background-color: #EAEAEA; border-radius: 50%; object-fit: contain;">
				<p><strong stlye="font-weight: 700;">Damian</strong> J&#xE4;ger</p>
				<p style="color: #A0A0A0;">programming prodigy, tech-geek with heart and soul, efficient coder</p>
			</div>
			<div style="width: calc((100% - 30px) / 3); min-width: 150px; text-align: center; box-sizing: border-box; padding: 15px; flex-grow: 1; flex-basis: auto;">
				<p><strong stlye="font-weight: 700;">Morris</strong> Janatzek</p>
				<p style="color: #A0A0A0;">IT-allrounder, always-before-deadline-finisher</p>
			</div>
		</div>
	</div>
</section>
<section style="width: 100%; min-height: 100vh; display: flex; align-items: center; justify-content: center;">
	<div style="text-align: center;">
		<h1 style="max-width: 935px; text-align: left;"><strong style="background-image: linear-gradient(90deg, #5C77FC, #5CB7FC); -webkit-text-fill-color: rgba(0, 0, 0, 0); -webkit-background-clip: text; font-weight: 700;">Why</strong> <span style="color: #A0A0A0;"> Resmit?</span></h1>
		<p style="max-width: 935px; text-align: left;">Altough there are probably countless reasons to use Resmit, we would like to highlight five of them:</p>
		<ul style="max-width: 935px; text-align: left; font-size: 20px; color: #696969;">
			<li>Trasferring files is as easy as entering a <b>four-digit code</b>. &#x2014; For signed in users, we offer <b>PushSend</b> to even further simplify the process: Files and links are transferred on the push of a button.</li>
			<li>Resmit is available as a <b>purely web-based service</b> at your disposal at any time. No installation needed and there is no hassle with device compatibility.</li>
			<li>Resmit enables you to effortlessly transfer <b>all types of files and links</b>.</li>
			<li>Optional <b>easy-to-use password protection</b> is available for more privacy.</li>
			<li>Streamlined UI focusing on what's important. Upload files by dropping them anywhere on the web page.</li>
		</ul>
	</div>
</section>
<section style="width: 100%; min-height: 100vh; display: flex; align-items: center; justify-content: center;">
	<div style="text-align: center;">
		<h1 style="max-width: 935px; text-align: right;"><strong style="background-image: linear-gradient(90deg, #5C77FC, #5CB7FC); -webkit-text-fill-color: rgba(0, 0, 0, 0); -webkit-background-clip: text; font-weight: 700;">How</strong> <span style="color: #A0A0A0;"> does Resmit work?</span></h1>
		<p style="max-width: 935px; text-align: left;">You can submit all types of files and links via the universal input field or just by dropping them anywhere on the web page. Your input is then paired with a four-digit code. Enter it in the universal input field on any other device to complete the transfer. That's it!</p>
	</div>
</section>