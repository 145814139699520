import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CodeData, CodeDataEntry, CodeService} from '../../shared/services/api/code.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {catchError, debounceTime, delay, map, pluck, shareReplay, switchMap, take} from 'rxjs/operators';
import {FileSaverService} from 'ngx-filesaver';
import {DialogService} from '../../shared/components/dialog/dialog.service';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {SelectOption} from '../../shared/components/select/select.component';
import {default as Masonry} from 'masonry-layout';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.less']
})
export class CodeComponent implements OnInit, OnDestroy, AfterViewInit {

  previewMultipleImages = environment.previewMultipleImages;

  codeData$: Observable<CodeData>;
  availableFor$: Observable<[string, number][]>;

  textChange$ = new Subject<[string, string, string]>();
  textChangeSubscription: Subscription;

  codeDataSubscription: Subscription;

  sortSelectOptions: SelectOption[] = [
    {value: (f: CodeDataEntry) => f.size, display: 'Size'},
    // {value: (f: CodeDataEntry) => f.type, display: 'Type'},
    {value: (f: CodeDataEntry) => f.data.split('.').reverse()[0], display: 'Extension'},
    {
      value: (f: CodeDataEntry) => {
        return f.data.split('/').reverse()[0].split('.')[0];
      }, display: 'Name'
    }
  ];

  @ViewChild('filesCodeContent', { static: false }) filesCodeContent: ElementRef;

  sortSelectOption$ = new BehaviorSubject<(f: CodeDataEntry) => any>(this.sortSelectOptions[1].value);

  constructor(private codeService: CodeService, private route: ActivatedRoute, private router: Router,
              private fileSaver: FileSaverService, private dialog: DialogService) {
    this.codeData$ = this.route.params.pipe(
      switchMap(params => this.codeService.getCode$(params.code)),
      map(codeData => {
        return codeData;
      }),
      catchError(() => {
        this.router.navigateByUrl('/error');
        return of(null);
      }),
      shareReplay()
    );

    this.availableFor$ = timer(0, 1000).pipe(
      switchMap(() => this.codeData$.pipe(pluck('expiresAt'))),
      map((expiresAt: Date) => {
        const expiresAtMoment: moment.Moment = moment(expiresAt);
        const now: moment.Moment = moment();
        const diff: moment.Duration = moment.duration(expiresAtMoment.diff(now));
        const result: [string, number][] = [];

        const days = diff.days();
        if (days) {
          result.push(['d', days]);
        }

        const hours = diff.hours();
        if (hours || days) {
          result.push(['h', hours]);
        }

        const minutes = diff.minutes();
        if (minutes || hours || days) {
          result.push(['m', minutes]);
        }

        const seconds = diff.seconds();
        if (seconds || minutes || hours || days) {
          result.push(['s', seconds]);
        }

        return result;
      })
    );

    this.codeData$.pipe(take(1)).subscribe((codeData) => {
      if (codeData.data[0].type === 'LINK') {
        if (environment.openUrlInNewTab) {
          window.open(codeData.data[0].data);
        } else {
          window.location.href = codeData.data[0].data;
        }
      }
    });

    this.textChangeSubscription = this.textChange$.pipe(
      debounceTime(300)
    ).subscribe(([code, codeDataId, newText]) => {
      this.storeTextChange(code, codeDataId, newText);
    });
  }

  ngOnInit(): void {
  }

  downloadText(entry: CodeDataEntry) {
    this.fileSaver.saveText(entry.data, `${entry.codeDataId}.txt`);
  }

  downloadFile(entry: CodeDataEntry) {
    this.codeService.downloadEntry$(entry).subscribe(blob => {
      const filenameParts = entry.data.split('/');
      this.fileSaver.save(blob, filenameParts[filenameParts.length - 1], undefined, { type: blob.type });
    });
  }

  textChanged(code: string, codeDataId: string, newText: string) {
    this.textChange$.next([code, codeDataId, newText]);
  }

  storeTextChange(code: string, codeDataId: string, newText: string) {
    this.codeService.updateText$(code, codeDataId, newText).subscribe(success => {
      if (!success) {
        this.dialog.show('Saving of text content failed. Retry?', () => this.storeTextChange(code, codeDataId, newText), 'Retry');
      }
    });
  }

  ngOnDestroy(): void {
    this.textChangeSubscription?.unsubscribe();
    this.codeDataSubscription?.unsubscribe();
  }

  ngAfterViewInit(): void {
    let masonry: any;

    this.codeDataSubscription = this.codeData$.pipe(
      switchMap(() => this.sortSelectOption$),
      delay(20)
    ).subscribe(() => {
      masonry?.destroy();

      if (this.filesCodeContent) {
        masonry = new Masonry(this.filesCodeContent.nativeElement, {
          columnWidth: '.code-type-file',
          itemSelector: '.code-type-file'
        });
      }
    });
  }
}
