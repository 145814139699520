import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartComponent } from './pages/start/start.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { AboutComponent } from './pages/about/about.component';
import { SupportHelpComponent } from './pages/support-help/support-help.component';
import { TermsOfServiceComponent } from './pages/term-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { LegalNoticeComponent } from './pages/legal-notice/legal-notice.component';
import { environment } from '../environments/environment';
import { CodeComponent } from './pages/code/code.component';
import { ErrorComponent } from './pages/error/error.component';
import {LoggedInGuard} from './shared/guards/logged-in.guard';

// Define the title displayed in header bar by setting the value of data. Example: data: { title: 'Example title' }
// If no title is defined and a code in params is available the code is displayed
// If no title or code is present a question mark is displayed

const routes: Routes = [
  { path: 'start', component: StartComponent },
  { path: 'code/:code', component: CodeComponent },
  { path: 'settings', component: SettingsComponent, data: { title: 'Settings' }, canActivate: [ LoggedInGuard ] },
  { path: 'about', component: AboutComponent, data: { title: 'About' } },
  { path: 'support-help', component: SupportHelpComponent, data: { title: 'Support & Help' } },
  { path: 'terms-of-service', component: TermsOfServiceComponent, data: { title: 'Terms of Service' } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
  { path: 'legal-notice', component: LegalNoticeComponent, data: { title: 'Legal Notice' } },
  { path: 'error', component: ErrorComponent, data: { title: 'Error' } },
  { path: 'c/:code', redirectTo: 'code/:code', pathMatch: 'full' },
  { path: '**', redirectTo: 'start', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: environment.useRoutingHash })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
