import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {AuthTokenService} from './auth-token.service';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {AuthService} from './api/auth.service';

@Injectable()
export class AuthTokenInterceptorService implements HttpInterceptor {
  constructor(private authTokenService: AuthTokenService, private authService: AuthService) {}

  /**
   * Intercepts all requests and appends a header with the auth token if available
   * @param req The request to intercept
   * @param next The handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.endsWith('/auth/token') && req.method === 'POST') {
      return next.handle(req);
    }

    if (!!this.authTokenService.authToken) {
      const reqClone = req.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authTokenService.authToken.access_token}`
        }
      });

      return next.handle(reqClone).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error?.status === 401 && this.authTokenService.authToken) {
            return this.authService.refreshToken$(this.authTokenService.authToken.refresh_token).pipe(
              tap(response => {
                this.authTokenService.setData({
                  ...this.authTokenService.authToken,
                  access_token: response.access_token
                }, true);
              }),
              catchError((refreshError: HttpErrorResponse) => {
                if (refreshError?.status === 401) {
                  this.authTokenService.resetData();
                  this.authTokenService.tokenWrongEvent.emit();
                }

                return throwError(refreshError);
              }),
              switchMap(() => {
                return this.intercept(reqClone, next);
              })
            );
          }

          return throwError(error);
        })
      );
    }

    return next.handle(req);
  }
}
