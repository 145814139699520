<div class="loading-animation-container" *ngIf="loadingService.active$ | async" [@fadeIn]>
  <div class="loading-animation">
    <svg viewBox="25 25 50 50">
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop class="strokeColor1" offset="0%"></stop>
          <stop class="strokeColor2" offset="100%"></stop>
        </linearGradient>
      </defs>
      <circle cx="50" cy="50" r="20" fill="none" stroke="url(#gradient)" stroke-width="2.5"
              stroke-miterlimit="10"></circle>
    </svg>
  </div>
</div>
